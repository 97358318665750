body{


    @mixin mqmin ($lowerValue){
        @media only screen and (min-width: $lowerValue) {
            @content
        }
    }


    @mixin mq($biggerValue){
        @media only screen and (max-width: $biggerValue) {
            @content
        }
    }


    .page_container{

        .main_content{
            width:100%;
            height: 100%;
            box-sizing: border-box;
            padding: 0;
            margin:0;

        ////////////////////////////////////////////////// HOME/////////////////////////////////////////////
        ////////////////////////////////////////////////// HOME/////////////////////////////////////////////
        ////////////////////////////////////////////////// HOME/////////////////////////////////////////////

            .servicespage_contacts{
                position: relative;
                padding: 0px;

                .servicespage_background_contacts{
                    padding-bottom: 100px;
                    padding-top: 180px;
                    background-image: url(../img/gallery/businesswoman-busy-computer-1080855-1024x705.jpg);
                    position: relative;
                    background-position: center center;
                    background-size: cover;
                    overflow: hidden;

                    .background_layer_contacts{

                        .servicespage_wrapper_contacts{
                            display: flex;
                            flex-flow: row wrap;
                            justify-content: center;

                            position: relative;
                            z-index: 3;
                            width: 80%;
                            max-width: 1080px;
                            margin-right: auto;
                            margin-left: auto;

                            .servicespage_content_contacts{
                                width: 100%;
                                align-self: center;

                                .servicespage_header_contacts{
                                    max-width: 800px;
                                    width: 80%;
                                    margin: 20px auto;
                                    padding: 10px;
                                    text-align: center;

                                    h4{
                                        font-family: 'Raleway',Helvetica,Arial,Lucida,sans-serif;
                                        font-weight: 700;
                                        text-transform: uppercase;
                                        font-size: 54px;
                                        letter-spacing: 2px;
                                        color:white;
                                        line-height: 1.1em;
                                        padding-bottom: 10px;

                                        @include mq(650px){
                                            font-size: 40px;
                                        }

                                        @include mq(420px){
                                            font-size: 35px;
                                        }

                                        @include mq(320px){
                                            font-size: 26px;
                                        }

                                        @include mq(280px){
                                            font-size: 20px;
                                            letter-spacing: 2px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .servicespage_background_contacts::before{
                    content: "";
                    position: absolute;
                    left: 0; right: 0;
                    top: 0; bottom: 0;
                    background-color: rgba(0,0,0,0.77);
                }
            }



            ////////////////////////////////////////////////// CONTACTS PAGE /////////////////////////////////////////////
            ////////////////////////////////////////////////// CONTACTS PAGE /////////////////////////////////////////////
            ////////////////////////////////////////////////// CONTACTS PAGE /////////////////////////////////////////////


            .contacts{
                background: #f9fcff;
                padding: 140px 0px 82px 0px;
                position: relative;

                @include mqmin(1350px){
                    padding: 54px 0;
                }

                @include mq(980px){
                    padding: 82px 0;
                }

                .contacts_wrapper{
                    max-width: 1080px;
                    width: 80%;
                    position: relative;
                    margin: auto;
                    overflow: hidden;

                    @include mqmin(1350px){
                        padding: 27px 0;
                    }

                    @include mqmin(981px){
                        padding: 2% 0;
                    }

                    @include mq(980px){
                        padding: 30px 0;
                        width: 85%;
                    }

                    .contacts_leftcol{
                        width: 47.25%;
                        margin-right: 5.5%;
                        float: left;
                        position: relative;
                        z-index: 9;

                        @include mq(980px){
                            width: 100%;
                            margin-bottom: 30px;
                        }

                        .contleftcol_container{
                            margin-bottom: 0;
                            overflow: hidden;

                            #contact_form{
                                margin-left: -3%;

                                .form_content{
                                    margin-bottom: 3%;
                                    padding: 0 0 0 3%;

                                    .form_input{
                                        text-decoration: none;
                                        width: 100%;
                                        font-family: 'Raleway',Helvetica,Arial,Lucida,sans-serif;
                                        font-weight: 600;
                                        font-size: 13px;
                                        letter-spacing: 1px;
                                        text-transform: uppercase;
                                        color: #000000;
                                        padding: 20px;
                                        border-top: 0px;
                                        border-right: 0px;
                                        border-left: 0px;
                                        border-bottom:2px solid #F48D1F;
                                        background: #f9fcff;
                                    }



                                }

                                .submit{
                                    margin-top: -1.5%;
                                    box-sizing: border-box;

                                    .button{

                                        height: 100%;
                                        padding: 0.3em 1.1em;
                                        margin: 0 0 0 15px;
                                        display: inline-block;
                                        cursor: pointer;
                                        background-color: black;
                                        border-width: 0px;
                                        border-radius: 50px;
                                        font-size: 13px;
                                        font-family: 'Raleway',Helvetica,Arial,Lucida,sans-serif;
                                        font-weight: 600;
                                        color: #F48D1F;

                                    }


                                }


                            }

                        }

                    }


                    .contacts_righttcol{
                        margin-right: 0;
                        width:47.25%;
                        padding-top: 0px;
                        float: left;
                        position: relative;
                        z-index: 9;

                        @include mq(980px){
                            margin-bottom: 0;
                            width: 100%;
                        }

                        .empty_div{
                            margin-bottom: 30px;

                            .empty_div_inner{
                                position: relative;

                                h2{
                                    margin-bottom: 10px;
                                    padding-bottom: 10px;

                                }
                            }
                        }

                        .info_wrapper{
                            margin-bottom: 5.82%;

                            &:last-of-type{
                                padding-bottom: 15px;
                                margin-top: 16px;
                            }

                            .info_wrapper_contain{
                                position: relative;
                                max-width: 550px;
                                margin: 0 auto;

                                .icon{
                                    display: table-cell;
                                    width: 32px;
                                    line-height: 0;
                                    max-width: 100%;
                                    margin-bottom: 30px;
                                    text-align: center;

                                    i{
                                        display:block;
                                        margin: auto;
                                        color: #ffffff;
                                        background-color: #F48D1F;
                                        opacity: 1;
                                        padding: 8px;
                                        font-size:16px;
                                        display: table-cell;
                                        border-radius: 100%;
                                    }

                                    #phone{
                                        transform: rotate(90deg);
                                    }



                                }

                                .icon_text{
                                    text-align: left;
                                    padding-left: 15px;
                                    display: table-cell;
                                    vertical-align: top;

                                    h4{
                                        padding-bottom: 0;
                                        padding-top: 5px;
                                        font-family: 'Karla',Helvetica,Arial,Lucida,sans-serif;
                                        font-size: 16px;
                                        line-height: 1.1em;
                                        color: #4c5b75;
                                    }
                                }
                            }
                        }


                    }






                }
            }
        }
    }
}


/* Djinn code bellow */
.g-recaptcha {margin:0px 0px 25px 3%;}
#mail-status {padding: 14px 40px;width: 100%;display:none; font-size: 1em;color: rgb(40, 40, 40);}
.error{background-color: #F7902D;  margin-bottom: 40px;}
.success{background-color: #48e0a4; }
