body{
    height: 100%;
    width:100%;
    overflow-x:hidden;
    font-family: 'Karla',Helvetica,Arial,Lucida,sans-serif;
    line-height:1.9em;
    color: #49556b;
    font-size: 16px;


    .column{
        float: left;
        position: relative;
        z-index: 9;

        @media only screen and (max-width:980px){
            float:none;
        }
    }

    @mixin mq($biggerValue){
        @media only screen and (max-width: $biggerValue) {
            @content
        }
    }

    .page_container{
        width: 100%;
        padding-top: 0px;
        overflow-y:hidden;
        margin-top: -1px;
        transition: margin-top .4s ease-in-out;



        ////////////////////////////////////////////////// MAINNAV/////////////////////////////////////////////
        ////////////////////////////////////////////////// MAINNAV/////////////////////////////////////////////
        ////////////////////////////////////////////////// MAINNAV/////////////////////////////////////////////

        .index_nav{

            .main_nav_index{
                width: 100%;
                top: 0px;
                // background-color: rgba(255,255,255,0);
                background-color: black;
                z-index:99999;
                font-weight: 500;
                line-height: 23px;
                display:  block;
                position:fixed;
    
    
    
                @include mq (980px){
                    background-color: rgba(255,255,255,0);
                    display:  block;
                    position: absolute;
    
                }
    
                .nav_container_index{
                    max-width:90%;
                    width:90%;
                    margin:auto;
                    overflow: hidden;
    
    
                    .logo_container_index{
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        text-align:left;
                        font-weight: 500;
                        line-height: 23px;
    
                        .logo_helper_index{
                            display: inline-block;
                            width: 0;
                            height: 100%;
                            vertical-align: middle;
                        }
                        
                        a{
                            color: #42ddcd;
                            text-decoration: none;
                            text-align: left;
                            box-sizing: border-box;
                            font-weight: 500;
                            line-height: 23px;
    
                            #logo_index{
                                display: inline-block;
                                float:none;
                                max-height:54%;
                                margin-bottom: 0;
                                max-width: 100%;
                                height: auto;
                                cursor: pointer;
                                vertical-align: middle;
    
                                @include mq(980px){
                                    max-width: 50%;
                                }
                            }
                        }
                    }
    
                    .header_index{
                        padding-left: 119px;
                        padding-top: 33px;
                        float: right;
                        
                        // overflow:hidden;
                        
                        @include mq (980px){
                            // display:block;
                            padding-top: 24px;
                        }
    
                        .menu_index{
                            float: left;
                            line-height: 0;
                            display: block;
                            font-weight: 600;
                            overflow:hidden;
    
                            @include mq (980px){
                                display: none;
                            }
    
    
    
                            .menu_container_index{
                                list-style: none;   
                                height: 100%;
                                float: left;
                                line-height: 0;
    
    
    
                                .menu_content_index{
                                    text-decoration: none;
                                    padding-right: 30px;
                                    display:inline-block;
                                    position: relative;
                                    line-height: 1em;
                                    font-size: 14px;
                                    line-height: 1em;
                                    padding-bottom:0px;
    
    
    
                                    a{
                                        display: block;
                                        position: relative;
                                        text-decoration: none;
                                        padding-bottom:33px;
                                        color:white;
                                        font-size: 17px;
                                        font-weight: 400;
                                    }
    
                                    &:last-of-type{
                                        padding-right:0;
                                    }
        
    
                                    a:hover{
                                        transition: opacity .4s ease-in-out;
                                    }
    
                                }
    
                            }
                        }
    
    
                        .mobileheader_index{
                            display:none;
                            float: right;
        
                            @include mq(980px){
                                display: block;
                            }
    
                            .mobileheader_container_index{
                                display: block;
        
                                .mobile_menu_bar_index{
                                    padding-bottom:24px;
                                    display: block;
                                    position: relative;
                                    line-height: 0;
        
        
                                    i{
                                        font-size: 32px;
                                        color:  black;
                                        position: relative;
                                        top: 0;
                                        left: 0;
                                        cursor: pointer;    
                                    }
                                }
        
                                .mobile_menu_index{
                                    max-width: 90%;
                                    width: 90%;
                                    position: absolute;
                                    top: 80px;
                                    background-color: rgba(255,255,255,0);
                                    background-color: rgba(29, 51, 84,0.6);
                                    border-top: 3px solid black;
                                    margin:0 5% 0 5%;
                                    display: none;
                                    left: 0;
                                    box-shadow: 0 2px 5px rgba(0,0,0,.1);
                                    padding: 5%;
    
                                            
                                    .mobile_menu_content_index{
                                        list-style: none;
    
                                        a{
                                            text-decoration: none;
                                            visibility: visible;
                                            font-family: 'Karla',Helvetica,Arial,Lucida,sans-serif;
                                            font-weight: 600;
                                            line-height: 23px;
                                            font-size: 14px;
                                            color: #ffffff;
                                            display: block;
                                            padding: 10px 5%;
                                            border-bottom: 1px solid rgba(8, 84, 155,.1);
                                        }
                                    }
    
    
                                }     
                                
                                .openmenu_index{
                                    display: block;
                                }
                            }
    
                        }
                    } 
    
                }
            }

        }


        

        .main_content{
            width:100%;
            height: 100%;
            box-sizing: border-box;
            padding: 0;


            ////////////////////////////////////////////////// HOME/////////////////////////////////////////////
            ////////////////////////////////////////////////// HOME/////////////////////////////////////////////
            ////////////////////////////////////////////////// HOME/////////////////////////////////////////////

            .home{
                padding: 0px;
                position: relative;
                height: 100%;
                background-color:#ffffff;
                overflow: hidden;

                .home_wrapper{
                    position: relative;
                    overflow: hidden;
                    padding-top:234px;
                    padding-bottom: 180px;
                    margin-top: -64px;
                    margin-bottom: -8px;
                    // background-color: #1e2a66;

                    .home_background{
                        background-image: url(../img/gallery/bethany-legg-9248-unsplash.jpg);
                        background-attachment: scroll;
                        overflow: hidden;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        background-repeat: no-repeat;
                        background-position: top center;
                        background-size: cover;
                    }


                    .logo{
                        position: relative;
                        z-index: 3;
                        max-width: 1080px;
                        width: 80%;
                        height: 100%;
                        box-sizing: border-box;
                        margin-right: auto;
                        margin-left: auto;
                        

                        .logo_wrapper{
                            align-self: center;
                            width: 100%;
                            height: 100%;
                            overflow: hidden;

                            .logo_content{
                                max-width: 40%;
                                width: 80%;
                                margin: 20px auto;
                                padding: 10px;
                                text-align: center;

                                @include mq (980px){
                                    max-width: 50%;
                                    width: 100%;                    
                                }

                                @include mq (600px){
                                    max-width: 230px;
                                    width: 100%;                    
                                }
                              

                                .company_logo{
                                    max-width: 100%;
                                    height: auto;
                                    margin-top: 3vw;
                                    margin-bottom: 3vw;
                                }

                                .empty{
                                    max-width: 430px;
                                    margin-bottom: 40px;

                                    p{
                                        padding-bottom: 0;
                                    }
                                }

                                .btn{
                                    text-decoration: none;
                                    padding: 12px 35px;
                                    background: #000000;
                                    border: 2px solid #000000;
                                    border-radius: 61px;
                                    display: inline-block;
                                    margin-top: 20px;
                                    overflow: hidden;
                                
                                    p{
                                        letter-spacing: 1px;
                                        font-size: 15px;
                                        font-family: 'Poppins',Helvetica,Arial,Lucida,sans-serif;
                                        font-weight: 500;
                                        text-transform: uppercase;
                                        color: #F48D1F;
                                        line-height: 1.7em;
                                        text-shadow: 0.18em 0em 0.3em rgba(0,0,0,0.4);

                                        @include mq(710px){
                                            font-size: 13px;
                                            font-weight: 300;
                                        }
                                    }

                                    @include mq(480px){
                                        margin-left: 0px;
                                        display: block;
                                        text-align: center;
                                        font-size: 12px;
                                        font-weight: 200;
                                        letter-spacing: 0.5px;
                                        padding: 6px 17.5px;
                                        line-height: 1.7em;
                                        text-transform: none;

                                    }

                                    @include mq(375px){
                                        display: none;
                                    }
                                }

                                .btn_min{
                                    display:none;
                                    overflow: hidden;

                                    @include mq(375px){
                                        display: block;
                                        text-decoration: none;
                                        padding: 12px 35px;
                                        background: black;
                                        border: 2px solid black;
                                        border-radius: 61px;
                                        font-family: 'Poppins',Helvetica,Arial,Lucida,sans-serif;
                                        display: inline-block;
                                        margin-top: 20px;
                                        text-shadow: 0.18em 0em 0.3em rgba(0,0,0,0.4);
                                        color: #F48D1F;
                                        text-align: center;
                                        margin-left: 0px;
                                        margin-right: 2px;
                                        font-size: 12px;
                                        font-weight: 200;
                                        letter-spacing: 0.5px;
                                        padding: 6px 17.5px;
                                        line-height: 1.7em;
                                        text-transform: none;

                                        @include mq (275px){
                                            font-size: 10px;
                                            font-weight: 100;

                                        }
                                    }

                                }

                            }

                        }


                    }
                }
            }


            ////////////////////////////////////////////////// ABOUTUS /////////////////////////////////////////////
            ////////////////////////////////////////////////// ABOUTUS /////////////////////////////////////////////
            ////////////////////////////////////////////////// ABOUTUS /////////////////////////////////////////////

            .aboutus{
                width: 100%;
                padding: 4% 0;
                background-color: #f9fcff;
                position: relative;
                overflow: hidden;

                @include mq(980px){
                    padding: 50px 0;
                }

                .aboutus_container{
                    max-width: 1239px;
                    width:80%;
                    position: relative;
                    padding: 137px 0px 168px 0px;
                    margin:auto;
                    display: flex;
                    direction: ltr;
                    z-index: 5;

                    @include mq(980px){
                        max-width: 1080px;
                        display: block;
                    }


                    .aboutus_col{
                        order:1;
                        width: 64%;
                        margin-right: 8%;

                        @include mq(980px){
                            width: 100%;
                            margin-bottom: 30px;
                        }


                        .aboutus_title{
                            color: #F48D1F;
                            font-family: 'Raleway',Helvetica,Arial,Lucida,sans-serif;
                            font-weight: 800;
                            text-transform: uppercase;
                            font-size: 33px;
                            margin-bottom: 30px;

                            h4{
                                padding-bottom: 0;
                                position: relative;
                            }

                            @include mq(470px){
                                font-size: 26px;
                            }

                            @include mq(300px){
                                font-size: 22px;
                            }
                        }


                        .aboutus_text{
                            position: relative;
                            margin-bottom: 0;
                            padding-bottom: 0;
                            font-family: 'Raleway',Helvetica,Arial,Lucida,sans-serif;
                            font-size: 14px;
                            text-align: justify;

                            p{
                                padding-bottom: 1em;
                                font-weight: 500;
                                color:#666;

                                // img{
                                //     max-width: 55px;
                                //     width:100%;
                                //     height: auto;
                                // }
                            }

                        }
                    }


                    .vision_col{
                        order:1;
                        width: 28%;
                        margin-right: 0;

                    
                        @include mq(980px){
                            width: 100%;
                            margin-bottom:0px;
                            
                        }

                        .vision_title{
                            color: #F48D1F;
                            font-family: 'Raleway',Helvetica,Arial,Lucida,sans-serif;
                            font-weight: 800;
                            text-transform: uppercase;
                            font-size: 33px;
                            margin-bottom: 30px;

                            h4{
                                padding-bottom: 0;
                                position: relative;
                            }    
                            
                            @include mq(470px){
                                font-size: 26px;
                            }

                            @include mq(300px){
                                font-size: 22px;
                            }
                        }


                        .vision_text{
                            position: relative;
                            margin-bottom: 0;
                            padding-bottom: 0;
                            font-family: 'Raleway',Helvetica,Arial,Lucida,sans-serif;
                            font-size: 14px;
                            text-align: justify;

                            p{
                                padding-bottom: 1em;
                                font-weight: 500;
                                color:#666;

                                // img{
                                //     max-width: 55px;
                                //     width:100%;
                                //     height: auto;
                                // }
                            }

                        }

                    }
                    
                }

                .divider_background_bottom{
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiMwODU0OWIiPjxwYXRoIGQ9Ik0xMjgwIDBsLTI2NiA5MS41MmE3Mi41OSA3Mi41OSAwIDAgMS0zMC43NiAzLjcxTDAgMHYxNDBoMTI4MHoiIGZpbGwtb3BhY2l0eT0iLjUiLz48cGF0aCBkPSJNMTI4MCAwbC0yNjIuMSAxMTYuMjZhNzMuMjkgNzMuMjkgMCAwIDEtMzkuMDkgNkwwIDB2MTQwaDEyODB6Ii8+PC9nPjwvc3ZnPg==);
                    background-size: 100% 100px;
                    filter: grayscale(100%) brightness(0);
                    bottom: 0;
                    height: 100px;
                    z-index: 1;
                    margin-bottom: -1px;
                    display:block;
                    width: 100%;
                    pointer-events: none;
                    position: absolute;

                }
            }
            ////////////////////////////////////////////////// ABOUTUS /////////////////////////////////////////////
            ////////////////////////////////////////////////// ABOUTUS/////////////////////////////////////////////
            ////////////////////////////////////////////////// ABOUTUS/////////////////////////////////////////////



             ////////////////////////////////////////////////// SERVICES&SOLUTIONS /////////////////////////////////////////////
            ////////////////////////////////////////////////// SERVICES&SOLUTIONS/////////////////////////////////////////////
            ////////////////////////////////////////////////// SERVICES&SOLUTIONS/////////////////////////////////////////////

            .services{
                padding: 226px 0px;
                background: #f9fcff;
                position: relative;

                .divider_background_top{

                    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIzMzVweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiMwODU0OWIiPjxwYXRoIGQ9Ik0wIDB2MTQwaDEyODBMMCAweiIgZmlsbC1vcGFjaXR5PSIuNSIvPjxwYXRoIGQ9Ik0wIDQydjk4aDEyODBMMCA0MnoiLz48L2c+PC9zdmc+);
                    width: 100%;
                    filter: grayscale(100%) brightness(0);
                    background-size: 100% 335px;
                    top: 0;
                    height: 335px;
                    transform: rotateY(180deg) rotateX(180deg);
                    margin-top: -1px;
                    background-repeat: no-repeat;
                    z-index: 1;
                    display: block;
                    position: absolute;

                }

                .services_row{
                    max-width: 1239px;
                    padding: 227px 0px 27px 0px;
                    display: flex;
                    direction: ltr;
                    z-index: 5;
                    position: relative;
                    width: 80%;
                    margin:auto;
                    overflow: hidden;

                    @include mq(980px){
                        display: block;
                    }

                    .serv_row_wrapper{
                        width: 64%;
                        margin-right: 8%;
                        position: relative;

                        @include mq(980px){
                            width: 100%;
                            margin-bottom:30px;
                            margin-right: 0;
                        }

                        .services_title{
                            margin-bottom: 30px;

                            h4{
                                font-family: 'Raleway',Helvetica,Arial,Lucida,sans-serif;
                                font-weight: 800;
                                text-transform: uppercase;
                                color: #F48D1F;
                                font-size: 33px;

                                @include mq(470px){
                                    font-size: 26px;
                                }
    
                                @include mq(300px){
                                    font-size: 22px;
                                }
                            }



                        }

                        .services_text{
                            margin-bottom: 0;
                            color: #49556B;
                            text-align: justify;

                            @include mq(980px){
                                margin-bottom: 0;

                            }

                            p{
                                
                                font-family: 'Raleway',Helvetica,Arial,Lucida,sans-serif;
                                font-size: 14px;
                                line-height: 1.9em;

                                strong{
                                    font-weight:700;
                                }

                                // img{
                                //     // max-width: 55px;
                                //     width:100%;
                                //     height: auto;
                                // }
                            }

                        }


                    }

                    
                    .services_link{
                        margin-right:0;
                        width: 28%;
                        float: right;
                        position: relative;

                        @include mq(980px){
                            float: none;
                            margin-bottom: 0;
                            margin-right:0;
                            width:100%;
                            
                        }

                        .link_btn{
                            text-align: center;
                            margin-top: 110px;

                            @include mq(980px){
                                margin-bottom: 0;
                            }

                            a{
                                display: inline-block;



                                p{
                                    padding: 0 20px 0 0;
                                    border-bottom: 1px black solid ;
                                    color: black;
                                    border-width: 3px;
                                    border-color: black;
                                    border-radius: 0px;
                                    letter-spacing: 2px;
                                    font-size: 16px;
                                    font-family: 'Raleway',Helvetica,Arial,Lucida,sans-serif;
                                    font-weight: 600;
                                    text-transform: uppercase;
                                    display: inline-block;

                                    i{
                                        position: absolute;
                                        display: inline-block;
                                        color: black;
                                        line-height: 1.7em;
                                        font-size: 1em;
                                        opacity: 1;
                                        margin-left: .3em;
                                        left: auto;
                                    }
                                }
                            }
                            

                        }

                    }


                }

                .services_row:last-of-type{
                    padding: 63px 0px 27px 0px;
                }
            }

        }


            // ////////////////////////////////////////////////// FOOTER /////////////////////////////////////////////
            // ////////////////////////////////////////////////// FOOTER /////////////////////////////////////////////
            // ////////////////////////////////////////////////// FOOTER /////////////////////////////////////////////


        .main_footer{
            display: block;
            background-color: black;
            width: 100%;
            bottom: 0;
            overflow: hidden;
            

            .footer_bottom{
                padding: 20px 0 20px 0;

                .footer_container{
                    width: 80%;
                    max-width: 1080px;
                    margin: auto;
                    position: relative;
                    overflow: hidden;
                    text-align: center;


                    .footer_info{

                        text-align: center;

                        p{
                            color: #ffffff;
                            font-size: 14px;
                            font-weight: 100;

                            
                            span{
                                font-size: 15px;
                                font-weight: 900;
                            }

                        }

                        
                    }
                }
            }
        }
    }
}
