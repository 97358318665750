body{


    @mixin mqmin ($lowerValue){
        @media only screen and (min-width: $lowerValue) {
            @content
        }
    }


    .page_container{

        .main_content{
            width:100%;
            height: 100%;
            box-sizing: border-box;
            padding: 0;
            margin:0;
        ////////////////////////////////////////////////// HOME/////////////////////////////////////////////
        ////////////////////////////////////////////////// HOME/////////////////////////////////////////////
        ////////////////////////////////////////////////// HOME/////////////////////////////////////////////
        
            ////////////////////////////////////////////////// SOLUTIONSPAGE /////////////////////////////////////////////
            ////////////////////////////////////////////////// SOLUTIONSPAGE /////////////////////////////////////////////
            ////////////////////////////////////////////////// SOLUTIONSPAGE /////////////////////////////////////////////
        
            .solutions{
                background: #f9fcff;
                padding: 97px 0px 54px 0px;;
                position: relative;
        
                .solutions_wrapper{
                    max-width: 1080px;
                    width: 80%;
                    position: relative;
                    margin: auto;

                    @include mqmin (1350px){
                        padding: 27px 0;
                    }

                    @include mqmin (981px){
                        padding: 2% 0;
                    }
        
                    .solutions_content{
                        width:100%;
                        margin-right: 0;
                        margin-bottom: 0;
        
                        .solutions_item{
                            cursor: pointer;
                            position: relative;
                            max-width: 550px;
                            margin: 0 auto 2.75% auto;

                            .solution_icon{
                                max-width:100%;
                                display: table-cell;
                                width: 32px;
                                line-height: 0;
                                margin-bottom:30px;

                                a{
                                    text-decoration: none;
                                    display: block;
                                    margin: auto;
                                    font-size: 23px;
                                    color: #F48D1F;
                                }
                            }


                            .solutions_text{
                                padding-left: 15px;
                                display: table-cell;
                                vertical-align: top;

                                .title_solution{
                                    
                                    a{
                                        font-family: 'Raleway',Helvetica,Arial,Lucida,sans-serif;
                                        text-decoration: none;
                                        font-weight: 700;
                                        font-size: 16px;
                                        color: #F48D1F;
                                        line-height: 1.1em;
                                    }
                                }

                                p{
                                    width: 100%;
                                    padding-bottom: 0;
                                    font-family: 'Raleway',Helvetica,Arial,Lucida,sans-serif;
                                    font-size: 14px;
                                    color:#49556B;    
                                    line-height: 1.9em;

                                }
                            }

                            &:last-of-type{
                                margin-bottom: 0;
    
                            }
                        }
                    }
                }
            }
        }
    }

}
