body{


    @mixin mqmin ($lowerValue){
        @media only screen and (min-width: $lowerValue) {
            @content
        }
    }

    
    @mixin mq($biggerValue){
        @media only screen and (max-width: $biggerValue) {
            @content
        }
    }


    .page_container{

        .main_content{
            width:100%;
            height: 100%;
            box-sizing: border-box;
            padding: 0;
            margin:0;

            ////////////////////////////////////////////////// SOLUTION1 PAGE /////////////////////////////////////////////
            ////////////////////////////////////////////////// SOLUTION1 PAGE /////////////////////////////////////////////
            ////////////////////////////////////////////////// SOLUTION1 PAGE /////////////////////////////////////////////


            .solution2{
                background: #f9fcff;
                position: relative;

                @include mq(980px){
                    padding: 50px 0;
                }

                @include mqmin(981px){
                    padding: 4% 0;
                }

                span{
                    font-weight: 700;
                }
        
                .solution2_wrapper{
                    max-width: 1080px;
                    width: 80%;
                    position: relative;
                    margin: auto;

                    @include mq (980px){
                        padding: 30px 0;
                    }
        
                    .solution2_content{
                        width:100%;
                        margin-right: 0;
                        margin-bottom: 0;

                        .solution2_inner_text{
                            margin-bottom: 0;
                            padding-bottom: 0;
                            position:relative;

                            p{
                                padding-bottom: 1em;

                                font-family: 'Raleway',Helvetica,Arial,Lucida,sans-serif;
                                font-size:14px;
                                color: #49556b;
                                font-weight: 500;

                                img{
                                    max-width: 100%;
                                    height: auto;
                                    background-color: #F48D1F;
                                }
                            }


                            .solution2_descript{
                                padding: 0 0 23px 1em;
                                line-height: 26px;
                                list-style-type: disc;
                                font-family: 'Raleway',Helvetica,Arial,Lucida,sans-serif;
                                font-size:14px;
                                font-weight: 500;
                                color: #49556b;

                                li{
                                    p{
                                        padding-bottom:0;
                                        font-family: 'Raleway',Helvetica,Arial,Lucida,sans-serif;
                                        font-size:14px;
                                        font-weight: 500;
                                        color: #49556b;

                                    }
                                }
                            }

                            .solution2_descript_numb{
                                padding: 2px 0 2px 20px;
                                line-height: 26px;
                                list-style-position: inside;
                                list-style-type: decimal;
                                list-style-position: inside;
                                color: #49556b;

                                li{
                                    line-height: 26px;
                                    padding-bottom:0;
                                    font-family: 'Raleway',Helvetica,Arial,Lucida,sans-serif;
                                    font-size:14px;
                                    font-weight: 500;
                                    color: #49556b;

                                }
                            }
                        }
                                                    
                        p:last-of-type{
                            padding-bottom: 0;
                        }
                        
                    }
                }

                .back_link{
                    margin-right:0;
                    width: 100%;
                    
                    overflow: hidden;


                    .back_link_btn{
                        max-width: 1080px;
                        width: 80%;
                        margin: 20px auto 20px auto;
                        overflow: hidden;

                        @include mq (600px){
                            margin: 10px auto 10px auto;
                        }


                        .back_btn_underlined{
                            display: block;
                            height: 100;
                            overflow: hidden;
                            margin: auto;

                            p{
                                padding: 0 20px 0 0;
                                border-bottom: 1px black solid ;
                                color: black;
                                border-width: 3px;
                                border-color: black;
                                border-radius: 0px;
                                font-size: 14px;
                                font-family: 'Raleway',Helvetica,Arial,Lucida,sans-serif;
                                font-weight: 400;
                                display: inline-block;
                                position: absolute;
                                left: auto;

                                i{
                                    display: inline-block;
                                    color: black;
                                    line-height: 1.7em;
                                    font-size: 1.1em;
                                    opacity: 1;
                                    margin-right: .5em;

                                }

                            }



                        }

                    }

                }



            }
        }
    }
}
